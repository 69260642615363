export const renderVacancySalary = (salary_from: number | string, salary_to: number | string, show_salary: number) => {
  if (show_salary && salary_from) {
    if (salary_to) {
      return salary_from + "    - " + salary_to + " ₾"
    } else {
      return salary_from + " ₾"
    }
  }

  return "შეთანხმებით"
}
